//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  props: {
    indexData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    url: {
      type: String,
      default: null,
    },
    referer: {
      type: String,
      default: null,
    },
    modalName: {
      type: String,
      default: "productInfo",
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },
  data() {
    return {
      showClients: true,
      showStorage: false,
      winTmpSubmiting: false,
      loading: false,
      editState: false,
      comWidth: "95%",
      attend: false,
      currentRowClient: null,
      currentRowStorage: null,
      productInfo: {
        Clients: [],
        Storages: [],
        CurrentStorage: null,
        CurrentClient: null,
        StorageAvailableDays: null,
        ClientAvailableDays: null,
      },
      orderInfo: null,
      promotionInfoClient: null,
      promotionInfoStorage: null,
      totalPriceServer: 0,
      formData: {
        teamId: 0,
        storageSKUId: 0,
        storageCount: 0,
        storagePrice: 0,
        storageAvailableDays: null,
        storagePeriod: null,
        storageUnit: null,
        clientSKUId: 0,
        clientCount: 0,
        clientPrice: 0,
        clientAvailableDays: null,
        clientPeriod: null,
        clientUnit: null,
        couponCode: null,
      },
      couponDetail: null,
      Rules: {
        teamId: [
          {
            required: true,
            message: "请选择团队",
            trigger: "blur",
          },
        ],
        storageSKUId: [
          {
            required: true,
            message: "请选择存储量",
            trigger: "blur",
          },
        ],
        clientSKUId: [
          {
            required: true,
            message: "请选择设备",
            trigger: "blur",
          },
        ],
        clientCount: [
          {
            type: "number",
            validator: this.clientCountValidator,
          },
        ],
        storageCount: [
          { type: "number", validator: this.storageCountValidator },
        ],
        clientAvailableDays: [
          { type: "number", validator: this.clientAvailableDaysValidator },
        ],
        storageAvailableDays: [
          { type: "number", validator: this.storageAvailableDaysValidator },
        ],
      },
    };
  },
  watch: {
    "formData.couponCode"() {
      this.couponDetail = null;
      if (this.formData.couponCode) {
        this.getCouponDetail(this.formData.couponCode);
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
    /**
     * 新增、修改或已过期，数量*时长/货品有效时长*货品单价
     * 原有货品：（原有数量或减少后的数量*（增加时长/货品有效时长）+增加数量*时长/货品有效时长）*货品单价
     * **/
    totalPrice() {
      var clientPrice = 0;
      if (this.formData.clientSKUId && this.formData.clientCount > 0) {
        clientPrice = this.calcSKUPrice(
          "client",
          this.formData.clientSKUId,
          this.formData.clientCount,
          this.formData.clientAvailableDays,
          this.formData.clientPeriod,
          this.formData.clientPrice
        );
      }
      var storagePrice = 0;
      if (this.formData.storageSKUId && this.formData.storageCount > 0) {
        storagePrice = this.calcSKUPrice(
          "storage",
          this.formData.storageSKUId,
          this.formData.storageCount,
          this.formData.storageAvailableDays,
          this.formData.storagePeriod,
          this.formData.storagePrice
        );
      }
      var total = clientPrice + storagePrice;
      total = total.toFixed(2);
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    btnBuyEnable() {
      if (this.totalPriceServer == 0) {
        return false;
      }
      var skus = [];
      if (
        this.formData.clientSKUId > 0 &&
        this.formData.clientCount > 0 &&
        this.formData.clientAvailableDays != 0
      ) {
        let noChange = false;
        if (
          this.productInfo.CurrentClient &&
          this.formData.clientSKUId == this.productInfo.CurrentClient.Id &&
          this.formData.clientAvailableDays ==
            this.productInfo.ClientAvailableDays &&
          this.formData.clientCount == this.productInfo.ClientCount
        ) {
          noChange = true;
        }
        if (!noChange) {
          skus.push({
            SKUId: this.formData.clientSKUId,
            Count: this.formData.clientCount,
            AvailableDays: this.formData.clientAvailableDays,
          });
        }
      }
      if (
        this.formData.storageSKUId > 0 &&
        this.formData.storageCount > 0 &&
        this.formData.storageAvailableDays != 0
      ) {
        let noChange = false;
        if (
          this.productInfo.CurrentStorage &&
          this.formData.storageSKUId == this.productInfo.CurrentStorage.Id &&
          this.formData.storageAvailableDays ==
            this.productInfo.StorageAvailableDays &&
          this.formData.storageCount == this.productInfo.StorageCount
        ) {
          noChange = true;
        }
        if (!noChange) {
          skus.push({
            SKUId: this.formData.storageSKUId,
            Count: this.formData.storageCount,
            AvailableDays: this.formData.storageAvailableDays,
          });
        }
      }
      return skus.length > 0;
    },
    orderDiscountInfo() {
      var info = "";
      if (this.orderInfo) {
        if (this.orderInfo.DiscountInfo) {
          info += this.orderInfo.DiscountInfo.split("\r\n")[0];
        }
        this.orderInfo.OrderSKUPrices.forEach((m) => {
          if (m.DiscountInfo) {
            info += m.DiscountInfo.split("\r\n")[0] + "\r\n";
          }
        });
      }
      info = info.replace(/\r\n/g, "<br/>");
      return info;
    },
    formatedPromotionInfoClient() {
      if (!this.promotionInfoClient) {
        return "";
      } else {
        return this.promotionInfoClient.replace(/\r\n/g, "<br/>");
      }
    },
    formatedCouponDetail() {
      if (!this.couponDetail) {
        return "";
      } else {
        return this.couponDetail.replace(/\r\n/g, "<br/>");
      }
    },
    formatedPromotionInfoStorage() {
      if (!this.promotionInfoStorage) {
        return "";
      } else {
        return this.promotionInfoStorage.replace(/\r\n/g, "<br/>");
      }
    },
  },
  filters: {
    truncateText(text) {
      if (!text) {
        return "无";
      }
      const maxWords = 80;
      if (text.length > maxWords) {
        text = text.substring(0, maxWords) + "...";
      }
      return text;
    },
  },
  methods: {
    clientCountValidator(rule, v, callback) {
      var minClientCount = this.clientMinCount();
      var minStorageCount = this.storageMinCount();
      if (
        v != 0 &&
        v < minClientCount &&
        this.formData.storageCount < minStorageCount
      ) {
        callback(new Error("客户端数量和存储量不能同时降配"));
      } else {
        callback();
      }
    },
    storageCountValidator(rule, v, callback) {
      var minClientCount = this.clientMinCount();
      var minStorageCount = this.storageMinCount();
      if (
        v != 0 &&
        v < minStorageCount &&
        this.formData.clientCount < minClientCount
      ) {
        callback(new Error("客户端数量和存储量不能同时降配"));
      } else {
        callback();
      }
    },
    clientAvailableDaysValidator(rule, v, callback) {
      if (
        this.productInfo.CurrentClient &&
        this.formData.clientSKUId == this.productInfo.CurrentClient.Id
      ) {
        if (
          this.formData.clientAvailableDays ==
          this.productInfo.ClientAvailableDays
        ) {
          callback();
        } else {
          if (
            this.formData.clientPeriod &&
            (v - this.productInfo.ClientAvailableDays) %
              this.formData.clientPeriod !=
              0
          ) {
            callback(
              new Error(
                `（设备时长-剩余时长）必须能被最小单位${this.formData.clientPeriod}整除`
              )
            );
          } else {
            callback();
          }
        }
      } else if (
        this.formData.clientPeriod &&
        v % this.formData.clientPeriod != 0
      ) {
        callback(
          new Error(
            `（设备时长-剩余时长）必须能被最小单位${this.formData.clientPeriod}整除`
          )
        );
      } else {
        callback();
      }
    },
    storageAvailableDaysValidator(rule, v, callback) {
      if (
        this.productInfo.CurrentStorage &&
        this.formData.storageSKUId == this.productInfo.CurrentStorage.Id
      ) {
        if (
          this.formData.storageAvailableDays ==
          this.productInfo.StorageAvailableDays
        ) {
          callback();
        } else {
          if (
            this.formData.storagePeriod &&
            (v - this.productInfo.StorageAvailableDays) %
              this.formData.storagePeriod !=
              0
          ) {
            callback(
              new Error(
                `（存储量时长-剩余时长）必须能被最小单位${this.formData.storagePeriod}整除`
              )
            );
          } else {
            callback();
          }
        }
      } else if (
        this.formData.storagePeriod &&
        v % this.formData.storagePeriod != 0
      ) {
        callback(
          new Error(
            `（存储量时长-剩余时长）必须能被最小单位${this.formData.storagePeriod}整除`
          )
        );
      } else {
        callback();
      }
    },
    getCurrenClientInfo() {
      var info = `${this.productInfo.CurrentClient.Name}：${
        this.productInfo.ClientCount + this.productInfo.CurrentClient.Unit
      }，可用功能：${
        this.productInfo.CurrentClient.Functions
      }，可用时长：${this.getAvailableDays(
        this.productInfo.ClientAvailableDays
      )}`;
      return info;
    },
    getCurrentStorageInfo() {
      var info = `${this.productInfo.CurrentStorage.Name}：${
        this.productInfo.StorageCount + this.productInfo.CurrentStorage.Unit
      }，可用时长：${this.getAvailableDays(
        this.productInfo.StorageAvailableDays
      )}`;
      return info;
    },
    getAvailableDays(day) {
      if (!day || day < 0) {
        return "永久有效";
      } else if (day == 0) {
        return "已过期";
      } else {
        return day + "天";
      }
    },
    /**
     * 新增、修改或已过期，数量*时长/货品有效时长*货品单价
     * 原有货品：（原有数量或减少后的数量*（增加时长/货品有效时长）+增加数量*时长/货品有效时长）*货品单价
     * **/
    calcSKUPrice(skuType, skuId, count, skuAvailableDays, period, price) {
      var totalPrice = 0;
      var skuPrice = 0;
      var isTeamProductAvailable = false;
      var isSameProduct = false;
      if (skuType == "client") {
        if (!this.productInfo.ClientAvailableDays) {
          isTeamProductAvailable = true;
        } else if (this.productInfo.ClientAvailableDays >= 0) {
          isTeamProductAvailable = true;
        }
        if (this.productInfo.CurrentClient) {
          isSameProduct = this.productInfo.CurrentClient.Id == skuId; //相同货品且团队有效即可叠加数量
        }
      } else {
        if (!this.productInfo.StorageAvailableDays) {
          isTeamProductAvailable = true;
        } else if (this.productInfo.StorageAvailableDays >= 0) {
          isTeamProductAvailable = true;
        }
        if (this.productInfo.CurrentClient) {
          isSameProduct = this.productInfo.CurrentStorage.Id == skuId; //相同货品且团队有效即可叠加数量
        }
      }
      if (isSameProduct && isTeamProductAvailable) {
        var skuCountNeedPay = 0;
        if (skuType == "client") {
          skuCountNeedPay = this.productInfo.ClientCount;
        } else {
          skuCountNeedPay = this.productInfo.StorageCount;
        }
        var newAddCount = 0;
        if (count >= skuCountNeedPay) {
          newAddCount = count - skuCountNeedPay;
        } else {
          skuCountNeedPay = count;
        }
        var oldAvailableDays = null;
        var newAddAvailableDays = null;
        var newAddAvailablePeriod = 0;
        if (skuType == "client") {
          oldAvailableDays = this.productInfo.ClientAvailableDays;
        } else {
          oldAvailableDays = this.productInfo.StorageAvailableDays;
        }
        if (skuAvailableDays && skuAvailableDays > oldAvailableDays) {
          newAddAvailableDays = skuAvailableDays - oldAvailableDays;
        }

        //有效天数计算
        let availablePeriod = 0;
        if (period) {
          if (period > 0) {
            //计算可用时长
            if (skuAvailableDays && skuAvailableDays > 0) {
              availablePeriod = Math.ceil(skuAvailableDays / period);
            }
            //计算增加可用时长
            if (newAddAvailableDays && newAddAvailableDays > 0) {
              if (newAddAvailableDays % period != 0) {
                this.$message({
                  message: `货品有效天数必须能被最小单位${period}整除`,
                  type: "error",
                });
                return 0;
              }
              newAddAvailablePeriod = newAddAvailableDays / period;
            }
          } else {
            availablePeriod = 1;
          }
        } else {
          availablePeriod = 1;
        }
        if (newAddAvailablePeriod > 0) {
          skuPrice += newAddAvailablePeriod * skuCountNeedPay * price;
        }
        if (newAddCount > 0) {
          skuPrice += availablePeriod * newAddCount * price;
        }
        skuPrice = Math.round(skuPrice, 2);
        if (skuPrice < 0) {
          this.$message({
            message: `货品售价不能小于0`,
            type: "error",
          });
          return 0;
        }
        totalPrice += skuPrice;
      } else {
        //有效天数计算
        let availablePeriod = 0;
        if (period) {
          if (period > 0) {
            if (skuAvailableDays && skuAvailableDays > 0) {
              if (skuAvailableDays % period != 0) {
                this.$message({
                  message: `货品有效天数必须能被最小单位${period}整除`,
                  type: "error",
                });
                return 0;
              }
              availablePeriod = skuAvailableDays / period;
            }
          } else {
            availablePeriod = 1;
          }
        } else {
          availablePeriod = 1;
        }
        if (availablePeriod > 0 && count > 0) {
          skuPrice = count * availablePeriod * price;
        }
        skuPrice = Math.round(skuPrice, 2);
        if (skuPrice < 0) {
          this.$message({
            message: `货品售价不能小于0`,
            type: "error",
          });
          return 0;
        }
        totalPrice += skuPrice;
      }
      return totalPrice;
    },
    clientMinCount() {
      var min = 0;
      if (
        this.productInfo.CurrentClient &&
        this.formData.clientSKUId == this.productInfo.CurrentClient.Id
      ) {
        min = this.productInfo.ClientCount;
      }
      return min;
    },
    storageMinCount() {
      var min = 0;
      if (
        this.productInfo.CurrentStorage &&
        this.formData.storageSKUId == this.productInfo.CurrentStorage.Id
      ) {
        min = this.productInfo.StorageCount;
      }
      return min;
    },
    sizeFormat(val, currentUnit, targetUnit) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      var currentIndex = units.indexOf(currentUnit);
      var targetIndex = units.indexOf(targetUnit);
      if (
        currentIndex == targetIndex ||
        currentIndex == -1 ||
        targetIndex == -1
      ) {
        return val;
      }
      var length = Math.abs(currentIndex - targetIndex);
      var mod = Math.pow(1024, length);
      if (currentIndex > targetIndex) {
        return val * mod;
      } else {
        return val / mod;
      }
    },
    getPriceDiscription(row) {
      var day = "永久使用";
      if (row.AvailableDays > 0) {
        day = `${row.AvailableDays}天`;
      }
      return `${row.Price}元/${row.Unit}/${day}`;
    },
    handleCurrentChangeClient(val) {
      if (
        this.productInfo.CurrentClient &&
        val.Id == this.productInfo.CurrentClient.Id
      ) {
        this.formData.clientAvailableDays =
          this.productInfo.ClientAvailableDays;
      } else {
        this.formData.clientAvailableDays = val.AvailableDays;
      }
      this.currentRowClient = val;
      this.formData.clientSKUId = val.Id;
      this.formData.clientPrice = val.Price;
      this.formData.clientPeriod = val.AvailableDays;
      this.formData.clientUnit = val.Unit;
      this.getPrice();
      this.getPromotions("client");
    },
    handleCurrentChangeStorage(val) {
      if (
        this.productInfo.CurrentStorage &&
        val.Id == this.productInfo.CurrentStorage.Id
      ) {
        this.formData.storageAvailableDays =
          this.productInfo.StorageAvailableDays;
      } else {
        this.formData.storageAvailableDays = val.AvailableDays;
      }
      this.currentRowStorage = val;
      this.formData.storageSKUId = val.Id;
      this.formData.storagePrice = val.Price;
      this.formData.clientPeriod = val.AvailableDays;
      this.formData.storageUnit = val.Unit;
      this.getPrice();
      this.getPromotions("storage");
    },
    setCurrentClient(row) {
      this.$nextTick(() => {
        this.$refs.clientTable.setCurrentRow(row);
      });
    },
    setCurrentStorage(row) {
      this.$nextTick(() => {
        this.$refs.storageTable.setCurrentRow(row);
      });
    },
    checkRule(value, msg) {
      return {
        validator: (rule, v, callback) => {
          if (Array.isArray(value)) {
            if (value.length == 0) {
              callback(new Error(msg));
            } else {
              callback();
            }
          } else if (!value) {
            callback(new Error(msg));
          } else {
            callback();
          }
        },
        required: true,
        trigger: ["blur", "change"],
      };
    },
    opened() {
      this.getProductList();
      this.formData.teamId = this.indexData.row.Id;
    },
    getPromotions(type) {
      var skuIds = [];
      if (type == "client" && this.formData.clientSKUId) {
        skuIds.push(this.formData.clientSKUId);
      }
      if (type == "storage" && this.formData.storageSKUId) {
        skuIds.push(this.formData.storageSKUId);
      }
      if (skuIds.length == 0) {
        return;
      }
      var params = {
        skuIds: skuIds,
      };
      this.$http
        .post("/Product/Promotions.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            if (type == "client") {
              this.promotionInfoClient = resp.data;
            } else if (type == "storage") {
              this.promotionInfoStorage = resp.data;
            }
          } else {
            this.$message({
              message: `获取优惠信息失败`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `获取优惠信息失败`,
            type: "error",
          });
        });
    },
    getCouponDetail(couponCode) {
      if (!couponCode) {
        return;
      }
      var params = {
        couponCode: couponCode,
      };
      this.$http
        .post("/Product/CouponDetail.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            this.couponDetail = resp.data;
          } else {
            this.$message({
              message: `获取折扣码信息失败`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `获取折扣码信息失败`,
            type: "error",
          });
        });
    },
    getPrice() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          var skus = [];
          if (
            this.formData.clientSKUId > 0 &&
            this.formData.clientCount > 0 &&
            this.formData.clientAvailableDays != 0
          ) {
            let noChange = false;
            if (
              this.productInfo.CurrentClient &&
              this.formData.clientSKUId == this.productInfo.CurrentClient.Id &&
              this.formData.clientAvailableDays ==
                this.productInfo.ClientAvailableDays &&
              this.formData.clientCount == this.productInfo.ClientCount
            ) {
              noChange = true;
            }
            if (!noChange) {
              skus.push({
                SKUId: this.formData.clientSKUId,
                Count: this.formData.clientCount,
                AvailableDays: this.formData.clientAvailableDays,
              });
            }
          }
          if (
            this.formData.storageSKUId > 0 &&
            this.formData.storageCount > 0 &&
            this.formData.storageAvailableDays != 0
          ) {
            let noChange = false;
            if (
              this.productInfo.CurrentStorage &&
              this.formData.storageSKUId ==
                this.productInfo.CurrentStorage.Id &&
              this.formData.storageAvailableDays ==
                this.productInfo.StorageAvailableDays &&
              this.formData.storageCount == this.productInfo.StorageCount
            ) {
              noChange = true;
            }
            if (!noChange) {
              skus.push({
                SKUId: this.formData.storageSKUId,
                Count: this.formData.storageCount,
                AvailableDays: this.formData.storageAvailableDays,
              });
            }
          }
          if (skus.length == 0) {
            this.totalPriceServer = 0;
            this.orderInfo = null;
            return;
          }

          this.totalPriceServer = "计算中...";
          this.orderInfo = null;
          var params = {
            teamId: this.indexData.row.Id,
            skus: skus,
            couponCodes: this.formData.couponCode
              ? [this.formData.couponCode]
              : [],
          };
          this.$http
            .post("/Order/Price.ashx", params)
            .then((resp) => {
              if (resp.res == 0) {
                this.orderInfo = resp.data;
                if (this.orderInfo) {
                  this.totalPriceServer = resp.data.TotalPrice;
                } else {
                  this.totalPriceServer = 0;
                }
              } else {
                this.totalPriceServer = 0;
                this.$message({
                  message: `计算订单售价失败`,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.totalPriceServer = 0;
              this.$message({
                message: `计算订单售价失败`,
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    getProductList() {
      if (!this.indexData.row) {
        return;
      }
      this.loading = true;
      var params = {
        teamId: this.indexData.row.Id,
      };
      this.$http
        .post("/Product/Client.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            this.productInfo = resp.data;
            if (this.productInfo.CurrentStorage) {
              this.formData.storageSKUId = this.productInfo.CurrentStorage.Id;
              this.formData.storagePrice =
                this.productInfo.CurrentStorage.Price;
              this.formData.storageAvailableDays =
                this.productInfo.StorageAvailableDays;
              this.formData.storagePeriod =
                this.productInfo.CurrentStorage.AvailableDays;
              this.formData.storageUnit = this.productInfo.CurrentStorage.Unit;
              this.formData.storageCount = this.productInfo.StorageCount;
              this.currentRowStorage = this.productInfo.Storages.find(
                (m) => m.Id == this.productInfo.CurrentStorage.Id
              );
              this.formData.storagePrice = this.currentRowStorage.Price;
            } else {
              if (this.productInfo.Storages.length > 0) {
                this.setCurrentStorage(this.productInfo.Storages[0]);
              }
              this.formData.storageCount = this.productInfo.StorageCount;
            }
            if (this.productInfo.CurrentClient) {
              this.formData.clientSKUId = this.productInfo.CurrentClient.Id;
              this.formData.clientPrice = this.productInfo.CurrentClient.Price;
              this.formData.clientAvailableDays =
                this.productInfo.ClientAvailableDays;
              this.formData.clientPeriod =
                this.productInfo.CurrentClient.AvailableDays;
              this.formData.clientUnit = this.productInfo.CurrentClient.Unit;
              this.currentRowClient = this.productInfo.Clients.find(
                (m) => m.Id == this.productInfo.CurrentClient.Id
              );
            } else {
              if (this.productInfo.Clients.length > 0) {
                this.setCurrentClient(this.productInfo.Clients[0]);
              }
            }
            this.formData.clientCount = this.productInfo.ClientCount;
            this.getPromotions("client");
            this.getPromotions("storage");
          } else {
            this.$message({
              message: `获取商品信息失败`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `获取商品信息失败`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 改变窗口状态的，搭配index页 添加和编辑按钮
    changeEditState() {
      var canEdit = false;
      if (!canEdit) {
        this.indexData.type = "";
        this.indexData.name = "商品详情";
      } else {
        var isNew = false;
        if (!this.indexData.row) {
          isNew = true;
        }
        this.indexData.type = `${isNew ? "Add" : "Edit"}`;
        this.indexData.name = `${!isNew ? "编辑商品" : "购买商品"}`;
      }
      this.editState = this.indexData.type === "Add" ? true : false;
    },
    // 提交... 细节
    submiting() {
      if (this.formData.teamId) {
        this.$refs.winTmp.toggleComfirm();
      } else {
        this.$refs.winTmp.toggleSubmit();
      }
      this.winTmpSubmiting = !this.winTmpSubmiting;
    },
    // 子组件提交共同的接口
    comSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          var skus = [];
          if (
            this.formData.clientSKUId > 0 &&
            this.formData.clientCount > 0 &&
            this.formData.clientAvailableDays != 0
          ) {
            let noChange = false;
            if (
              this.productInfo.CurrentClient &&
              this.formData.clientSKUId == this.productInfo.CurrentClient.Id &&
              this.formData.clientAvailableDays ==
                this.productInfo.ClientAvailableDays &&
              this.formData.clientCount == this.productInfo.ClientCount
            ) {
              noChange = true;
            }
            if (!noChange) {
              skus.push({
                SKUId: this.formData.clientSKUId,
                Count: this.formData.clientCount,
                AvailableDays: this.formData.clientAvailableDays,
              });
            }
          }
          if (
            this.formData.storageSKUId > 0 &&
            this.formData.storageCount > 0 &&
            this.formData.storageAvailableDays != 0
          ) {
            let noChange = false;
            if (
              this.productInfo.CurrentStorage &&
              this.formData.storageSKUId ==
                this.productInfo.CurrentStorage.Id &&
              this.formData.storageAvailableDays ==
                this.productInfo.StorageAvailableDays &&
              this.formData.storageCount == this.productInfo.StorageCount
            ) {
              noChange = true;
            }
            if (!noChange) {
              skus.push({
                SKUId: this.formData.storageSKUId,
                Count: this.formData.storageCount,
                AvailableDays: this.formData.storageAvailableDays,
              });
            }
          }
          if (skus.length == 0) {
            this.$message({
              message: `请选择规格及数量`,
              type: "info",
            });
            return;
          }
          this.submiting();
          const baseURL =
            window.location.protocol + "//" + window.location.host;
          var params = {
            teamId: this.formData.teamId,
            skus: skus,
            couponCodes: this.formData.couponCode
              ? [this.formData.couponCode]
              : [],
            returnUrl: baseURL + "/teamManagement",
          };
          this.$http
            .post("/Order/Pay.ashx", params)
            .then((res) => {
              if (res.res == 0) {
                if (res.data != "") {
                  let html = '<div style="display:none">';
                  html += res.data;
                  html += "</div>";
                  window.document.write(html);
                  this.$message({
                    message: `创建订单成功，正在跳转到支付页面...`,
                    type: "error",
                  });
                }
                this.submiting();
                this.$modal.hide("productInfo");
                this.$emit("eventComfirm");
              } else {
                this.submiting();
              }
            })
            .catch(() => {
              this.$message({
                message: `创建订单失败`,
                type: "error",
              });
              this.submiting();
            });
        } else {
          return false;
        }
      });
    },
    // 子组件事件3
    editClick() {
      this.$refs.editForm.clearValidate();
      if (this.editState === false) this.changeEditState();
    },
    // 关闭弹层前，重置数据
    beforeClose() {
      this.attend = false;
      this.loading = false;
      Object.assign(this.$data, this.$options.data());
      this.$router.replace({});
    },
    setLoading(val) {
      this.loading = val;
    },
  },
};
